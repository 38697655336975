export default {
  titles: {
    encoding: 'Encodage',
    inbound: 'Entrant',
    user_info: 'Informations utilisateur',
    settings: 'Paramètres',
    reset_daily_items: 'Réinitialiser les articles quotidiens ?',
    last_parcels: 'Derniers colis',
    select_item: 'Sélectionner un article',
    select_workstation: 'Sélectionner un poste de travail',
    select_language: 'Sélectionner une langue',
    support: 'Support',
    select_printer: 'Sélectionner une imprimante',
    shipment_to_confirm: 'Expédition à confirmer',
    sorting_details: 'Détails de ventilation',
    inventories: 'Inventaires',
    pin_modal: 'Entrez le code PIN pour forcer l’association',
    shipments: 'Expéditions',
    parcels: 'Emballer',
  },
  fields: {
    current_location: 'Emplacement actuel',
    identified_item: 'Article identifié',
    ignored_items: 'Articles ignorés',
    ignored_with_reason_items: 'Articles ignorés avec raison',
    error_items: 'Articles en erreur',
    warning_items: 'Articles en avertissement',
    umg: 'Umg',
    custom_class: 'Classe personnalisée',
    product_code: 'Code produit',
    as400username: 'Nom d’utilisateur AS400',
    item_components: 'Composants',
  },
  misc: {
    or: 'ou',
    select_place: `Bonjour {{user}}, sélectionnez votre lieu d’origine pour continuer`,
    back: 'Retour',
    refresh: 'Rafraîchir',
    save: 'Enregistrer',
    confirm: 'Confirmer',
    update_and_confirm: 'Mettre à jour et confirmer',
    no_carton_found: 'Aucun carton trouvé',
    confirm_continue: 'Confirmer et continuer',
    cancel: 'Annuler',
    close: 'Fermer',
    user: 'Utilisateur',
    workstation: 'Poste de travail',
    username: 'Nom d’utilisateur',
    name: 'Nom',
    password: 'Mot de passe',
    login: 'Connexion',
    encoding: 'Encodage',
    code: 'Code',
    ean_code: 'Code EAN',
    certilogo: 'Certilogo',
    enter_the_necessary_fields: 'Entrez les champs nécessaires pour démarrer l’opération',
    associated_items: 'Articles associés',
    associate: 'Associer',
    associated: 'Associé',
    verify: 'Vérifier',
    reading: 'Lecture',
    processing: 'Traitement',
    association: 'Association',
    associations: 'Associations',
    waiting: 'En attente',
    writing: 'Écriture',
    writing_progress: 'Écriture en cours...',
    partial_confirm: 'Confirmation partielle',
    retry: 'Réessayer',
    writing_association: 'Écriture et association',
    confirmed: 'Confirmé',
    unable_to_write: `Impossible d’écrire l’étiquette EPC : {{code}} pour le produit : {{productCode}}`,
    unable_to_get_next_epc: `Impossible d’obtenir l’EPC suivant pour : {{productCode}}`,
    unable_to_connect_to_workstation: 'Impossible de se connecter au poste de travail',
    tag_info: 'Informations sur l’étiquette',
    inbound: 'Entrant',
    outbound: 'Sortant',
    stop: 'Arrêter',
    start: 'Démarrer',
    pause: 'Pause',
    clear: 'Effacer',
    tag_encoded_today: 'Étiquette encodée aujourd’hui',
    transaction_date: 'Date de transaction',
    select_transaction_date: 'Sélectionner la date de transaction',
    ready_to_receive: 'Prêt à recevoir',
    change_password: 'Changer le mot de passe',
    logout: 'Déconnexion',
    are_you_sure_to_logout: 'Voulez-vous vraiment vous déconnecter ?',
    settings: 'Paramètres',
    roles: 'Rôles',
    email: 'Email',
    place: 'Lieu',
    items: 'Articles',
    description: 'Description',
    color: 'Couleur',
    size: 'Taille',
    part: 'Partie',
    total_reads: 'LECTURES TOTALES',
    total_ok: 'TOTAL OK',
    save_to_list: 'Enregistrer dans la liste',
    destination: 'Destination',
    origin: 'Origine',
    new_parcel: 'Nouveau colis',
    status: 'Statut',
    parcel: 'Colis',
    read_parcel: 'Lire le colis',
    parcels: 'Colis',
    shipment: 'Expédition',
    creation_date: 'Date de création',
    search: 'Rechercher',
    select_search: 'Rechercher...',
    detail: 'Détail',
    quantity: 'Quantité ({{quantity}})',
    only_quantity: 'Quantité',
    style: 'Style',
    material: 'Matériau',
    shipment_code_auto: 'Code d’expédition (auto)',
    parcel_code_auto: 'Code de colis (auto)',
    next: 'Suivant',
    parcels_to_receive: 'Colis à recevoir',
    confirm_parcel: 'Confirmer le colis',
    image_missing: 'IMAGE MANQUANTE',
    image: 'Image',
    loading: 'Chargement...',
    unknown_product: 'Produit inconnu',
    unknown_tag: 'Étiquette inconnue',
    invalid_tag: 'Étiquette invalide',
    stock: 'Stock',
    valid: 'Valide',
    unknown: 'Inconnu',
    actions: 'Actions',
    reprint: 'Réimprimer',
    product: 'Produit',
    product_items: 'Articles du produit',
    identifiers: 'Identifiants',
    zone: 'Zone',
    report_repair: 'Signaler une réparation',
    reason: 'Raison',
    request_code_change: 'Demander une modification du code',
    enable_workstation_emulation: 'Activer l’émulation du poste de travail',
    enable_browser_print_emulation: 'Activer l’émulation du Browser Print',
    filter: 'Filtrer',
    language: 'Langue',
    warning: 'Avertissement',
    success: 'Succès',
    info: 'Info',
    checklist: 'Liste de contrôle',
    optional: 'Optionnel',
    phone: 'Téléphone',
    version: 'Version',
    printer: 'Imprimante',
    new_shipment: 'Nouvelle expédition',
    upc: 'UPC',
    model_part_color_size: 'Modèle / Partie / Couleur / Taille',
    confirm_shipment: 'Confirmer l’expédition',
    receive: 'Recevoir',
    delete: 'Supprimer',
    delete_association: 'Supprimer l’association',
    change: 'Modifier',
    from: 'De',
    to: 'À',
    ignored: 'Ignoré',
    skipped: 'Sauté',
    ignored_with_reason: 'Ignoré avec raison',
    yes: 'Oui',
    no: 'Non',
    model: 'Modèle',
    typology: 'Typologie',
    no_result: 'Aucun résultat',
    expected: 'Attendu',
    read: 'Lu',
    missing: 'Manquant',
    unexpected: 'Inattendu',
    unexpected_parcel: 'Colis inattendu',
    ignored_plural: 'Ignorés',
    serial: 'Série',
    priority: 'Priorité',
    group: 'Groupe',
    required_quantity: 'Qté requise',
    sorted_quantity: 'Qté ventilée',
    missing_quantity: 'Qté manquante',
    barcode_bc: 'Code-barres BC',
    sorted_date: 'Date de ventilation',
    customer: 'Nom du client',
    list: 'Liste',
    list_position: 'Position dans la liste',
    select_date: 'Sélectionner une date',
    item_info: 'Informations sur l’article',
    item_events: 'Événements de l’article',
    operation_type: 'Type d’opération',
    operation_place: 'Lieu d’opération',
    loading_data: 'Veuillez patienter, chargement des données...',
    empty_item_events: 'Aucun événement trouvé',
    show_more: 'Afficher plus',
    hide_more: 'Masquer',
    production_order: 'Ordre de production',
    production_order_row: 'Ligne de production',
    production_order_sku: 'SKU de l’ordre de production',
    kill_tag: 'Désactiver l’étiquette',
    item_order: 'Commande d’article',
    item_row: 'Ligne d’article',
    pack_code: 'Code du colis',
    send_reads: 'Envoyer les lectures',
    items_add_inventory_success: 'Articles ajoutés à l’inventaire',
    new: 'Nouveau',
    confirm_mission: 'Confirmer la mission',
    confirm_mission_text: 'Êtes-vous sûr de vouloir confirmer la mission ?',
    parziali: 'Partiel',
    total_quantity: 'Quantité totale',
    accepted_quantity: 'Quantité acceptée',
    country_check: 'Contrôle du pays',
    showcase_check: 'Contrôle de vitrine',
    attention: 'Attention',
    clear_items: 'Cette action supprimera toutes les lectures, voulez-vous continuer ?',
    add_partial: 'Attention',
    add_partial_text: 'Il peut être nécessaire de créer une mission d’Assurance Qualité',
    add_partial_already_text:
      'Il peut être nécessaire de créer une mission d’Assurance Qualité. UMG a déjà travaillé. Voulez-vous écraser les articles lus ?',
    error_cq_mission: 'Attention, créez une mission pour le contrôle qualité',
    warning_zone_items: 'DANS_UNE_AUTRE_ZONE',
    internal_tag: 'Étiquette interne',
    external_tag: 'Étiquette externe',
    error_tag: 'Étiquette en erreur',
    awaiting: 'En attente',
    error_logs: 'Journaux d’erreur',
    event_logs: 'Journaux d’événements',
    box_closed: 'Boîte fermée',
    unexpected_list: 'Liste d’articles inattendus',
    pending_tags: 'Étiquettes en attente',
    selected_zone: 'Zone sélectionnée',
    pricing: 'Tarification',
    send_to_sap: 'Envoyer à SAP',
    write_tag: 'Écrire l’étiquette',
    identifier_tag: 'Étiquette d’identification',
    digit_identifier_tag: 'Saisir l’étiquette à écrire',
    target_epc: 'EPC cible',
    retry_write_tag: 'Une erreur d’écriture a été rencontrée. Voulez-vous essayer d’écrire l’étiquette à nouveau ?',
    ready: 'Prêt',
    offline: 'Hors ligne',
    shipping_date: 'Date d’expédition',
    update_only_product: 'Article déjà associé à un autre produit. Êtes-vous sûr de vouloir l’écraser ?',
    export: 'Exporter',
    select_zone: 'Sélectionner une zone',
    location_origin: 'Emplacement d’origine',
    confirm_kill_tag: 'Confirmer la désactivation de l’étiquette',
    select_tag_to_kill: 'Sélectionnez l’étiquette que vous souhaitez désactiver',
    retry_kill_tag:
      'Une erreur de suppression a été rencontrée. Voulez-vous essayer de désactiver l’étiquette à nouveau ?',
    navBackWarning:
      'Êtes-vous sûr de vouloir revenir en arrière ? Toutes les modifications non enregistrées seront perdues.',
    ready_to_read: 'Prêt à lire',
    remove_product_readings: 'Supprimer les lectures du produit',
    ok: 'OK',
    already_in_parcel: 'déjà dans le colis',
    operation_canceled_by_user: 'Opération annulée par l’utilisateur',
    load_more: 'chargement en cours',
  },
  error: {
    error: 'Erreur',
    errors: 'Erreurs',
    forbidden: 'Interdit',
    not_found: 'Non trouvé',
    unauthorized: 'Non autorisé',
    too_many_tags_read: 'Trop d’étiquettes lues (attendues {{expected}}, lues {{read}})',
    too_many_checklist_types:
      'Trop de types de check-list. Assurez-vous de sélectionner des colis avec le même type de check-list.',
    rfid_discrepancies_found:
      'Des divergences RFID ont été trouvées, effacez tout et réessayez ou confirmez avec divergences',
    certilogo_not_found: 'Certilogo "{{code}}" non trouvé',
    ean_not_found: 'EAN "{{code}}" non trouvé',
    production_order_not_found: 'Ordre de production "{{code}}" non trouvé',
    no_inbound_zones: 'Aucune zone d’entrée',
    no_operation_config: 'Configuration de l’opération non trouvée',
    not_supported_reading_mode: 'Mode de lecture non pris en charge',
    undefined_operation_config: 'La configuration de l’opération ne peut pas être indéfinie',
    certilogo_already_encoded: 'Certilogo "{{code}}" déjà encodé',
    tag_not_found: 'Étiquette non trouvée',
    missing_printer_id: 'Adresse IP de l’imprimante manquante',
    tag_unassociated: 'Étiquette non associée',
    tag_type_unexpected: 'Type d’étiquette inattendu',
    tag_already_associated: 'Étiquette déjà associée. Vous devez d’abord la dissocier',
    tags_not_associated: 'Une ou plusieurs étiquettes ne sont pas associées',
    tags_mismatch: 'Incohérence des étiquettes',
    workstation_could_not_be_started: 'Le poste de travail {{code}} n’a pas pu être démarré',
    authentication_failed: 'Échec de l’authentification',
    field_required: 'Le champ {{label}} est requis',
    unable_to_connect_to_rfid_reader: 'Impossible de se connecter au lecteur RFID. Vérifiez que l’appareil est allumé.',
    items_in_error_found: 'Articles en erreur trouvés',
    item_not_in_inbound: 'Article non présent en entrée',
    item_not_in_stock: 'Article non en stock',
    item_in_another_parcel: 'Article dans un autre colis',
    item_in_another_production_order: 'Article dans un autre ordre de production',
    connection: 'Erreur de connexion',
    connection_details: 'Aucune réponse du serveur. Vérifiez que votre connexion Internet ou votre VPN fonctionne.',
    field_invalid_default: 'Valeur par défaut "{{defaultValue}}" invalide pour le champ {{field}}',
    field_invalid_default_from_endpoint: 'Impossible de trouver une valeur pour le champ {{field}}',
    sorting_already_read: 'Article avec numéro de série {{serial}} déjà lu dans le groupe {{group}}',
    sorting_no_group: 'Article non triable',
    sorting_not_in_zone: 'Article avec numéro de série {{serial}} non triable. Déjà dans la zone {{zone}}',
    sorting_unknown: 'Étiquette {{tag}} non valide',
    sorting_unexpected: 'Étiquette inattendue',
    no_item_configuration: 'Aucune configuration d’article trouvée',
    item_creation_error: 'Erreur de création d’article',
    couldn_find_production_order: 'Impossible de trouver l’ordre de production',
    couldn_find_production_order_row: 'Impossible de trouver la ligne de l’ordre de production',
    missing_operation_option: 'Option d’opération manquante : {{error}}',
    item_session_list_create: 'Liste non créée',
    item_session_list_create_session: 'Session de liste non créée',
    item_session_list_confirm: 'Liste non confirmée',
    item_session_list_confirm_session: 'Session de liste non confirmée',
    form_validation: 'Validation du formulaire',
    form_validation_message: 'Erreur dans le remplissage du champ',
    select_place_error: 'Impossible d’enregistrer le lieu d’origine',
    self_user_data: 'Impossible d’enregistrer vos données',
    encoding_no_tags: 'Aucune autre étiquette n’a été lue. Opération annulée',
    only_virgin_tag: 'Seules des étiquettes vierges détectées',
    tags_mismatch_error: 'Veuillez lire une étiquette à la fois',
    tags_errors: 'Erreurs d’étiquettes',
    value_not_valid: 'La valeur entrée n’est pas valide',
    write_tag: 'Erreur inconnue lors de l’écriture de l’étiquette',
    WRONG_PRODUCTION_ORDER: 'Ordre de production incorrect',
    WRONG_PLACE: 'Mauvais emplacement',
    PRODUCTION_ORDER_ROW_CREATION_ERROR: 'Erreur de création de ligne d’ordre de production',
    force_tag_associated: 'Étiquette déjà associée. Réassocier ?',
    operation_confirm_error: 'Erreur lors de la confirmation de l’opération',
    missing_mandatory_qc: 'Aucun contrôle qualité. Continuer ?',
    wrong_qc_status: 'Le produit a été déclaré KO. Continuer ?',
    wrong_qc_size: 'Vérifier la taille. Continuer ?',
    wrong_qc_size_payload:
      'Vérifier la taille. Taille encodée : {{productSize}} - Taille physique : {{manualSize}}. Continuer ?',
    wrong_qc_model: 'Vérifier le modèle. Continuer ?',
    start_checklist: 'Check-list non démarrée',
    confirm_with_unexpected: 'Impossible de confirmer avec des étiquettes inattendues',
    confirm_with_overqty: 'Impossible de confirmer avec des étiquettes en trop',
    rfid_reader_initialization: 'Erreur inconnue lors de l’initialisation du lecteur RFID',
    remove_sap_item_error: 'Impossible de supprimer l’article',
    no_asn_selected: 'Aucun ASN sélectionné',
    submit_asn: 'Erreur lors de la soumission de l’ASN',
    zbpNotFound:
      'Agent Zebra Browser Print introuvable sur votre système. Assurez-vous qu’il est installé et en cours d’exécution',
    no_printer_selected: 'Aucune imprimante sélectionnée',
    zplTemplateNotDefined:
      "Modèle ZPL non défini. Veuillez définir un code de modèle dans l'attribut 'zpl-template-code' de la configuration de l'opération",
    no_print_template: 'Modèle d’impression introuvable',
    wrong_tag: 'Mauvaise étiquette',
    tag_error_or_not_authentic: 'Étiquette non authentique ou erreur d’authentification',
    authentication_timeout: 'Délai d’authentification dépassé',
    tid_missing: 'Tid manquant pour l’étiquette lue',
    on_destination: 'à destination {{destination}}',
    item_not_sortable: 'Article avec numéro de série {{serial}} non triable',
    search_error: 'Erreur de recherche',
    no_parcel_found_by_identifier: 'Aucun colis trouvé avec ces identifiants',
    kill_tag_not_allowed:
      'Il est impossible de désactiver une étiquette associée à un article. Pour la désactiver, dissociez d’abord l’article.',
    kill_tag_not_success: 'Échec de la désactivation de l’étiquette',
    nfc_tag_not_expected: 'Étiquette NFC non attendue pour ce produit',
    ventilation_quantity_reached: 'La quantité à ventiler pour ce produit a déjà été atteinte',
    confirm_not_allowed_with_errors: 'Confirmation non autorisée en présence d’erreurs',
    close_not_allowed_with_errors: 'Fermeture non autorisée en présence d’erreurs',
  },
  messages: {
    no_operations_found: 'Aucune opération trouvée. Vérifiez les permissions utilisateur dans le ControlPanel',
    not_allowed_operation: `Vous n’avez pas l’autorisation d’effectuer cette opération. Veuillez vérifier votre configuration`,
    no_workstations_found: 'Aucun poste de travail trouvé',
    no_workstation_selected: 'Aucun poste de travail sélectionné',
    are_you_sure_to_reset_daily_items: 'Êtes-vous sûr de vouloir réinitialiser les articles quotidiens ?',
    bring_an_item_to_antenna: 'Approchez un article de l’antenne',
    press_start_to_read: 'Appuyez sur le bouton Démarrer pour \ncommencer la lecture des produits RFID',
    reading_in_progress: 'Lecture en cours',
    waiting_for_product: 'En attente du produit...',
    paste_comma_separeted_tags: 'Collez des étiquettes séparées par des virgules puis appuyez sur Entrée...',
    connection_workstation_progress: 'Connexion au poste de travail en cours...',
    operation_success: 'Opération confirmée avec succès',
    outbound_success: 'Expédition confirmée avec succès',
    inbound_success: 'Entrée confirmée avec succès',
    pack_success: 'Emballage confirmé avec succès',
    transfer_to_zone_success: 'Transfert vers la zone confirmé avec succès',
    simple_item_list_success: 'Liste simple d’articles confirmée avec succès',
    list_empty: 'La liste est vide',
    looks_like_anything_in_list: 'On dirait que vous n’avez rien dans votre liste',
    generic_success: '{{code}} confirmé avec succès',
    generic_success_canceled: '{{code}} annulé avec succès',
    generic_error: 'Erreur {{code}}',
    fill_all_fields_and_search_an_item_configuration:
      'Remplissez tous les champs et recherchez une configuration d’article',
    encoding_success: 'Encodage confirmé avec succès',
    encoding_success_none: 'Article déjà associé précédemment',
    read_some_identifier_to_start_verification: 'Lisez un identifiant pour commencer la vérification',
    warning_items_found: 'Articles en avertissement trouvés',
    are_you_sure_you_want_to_confirm: 'Êtes-vous sûr de vouloir confirmer ?',
    item_session_list_confirm: 'Liste confirmée avec succès',
    operation_in_progress: 'Opération en cours, veuillez patienter...',
    sorting_error_zones: 'Zone d’origine non renseignée, impossible de continuer la ventilation',
    connection_in_progress: 'Connexion en cours...',
    checklist_there_are: 'Il y a',
    checklist_missings: 'articles manquants',
    checklist_overqty: 'articles en excès',
    checklist_unexpecteds: 'articles inattendus',
    checklist_confirm_mismatch_question: 'Êtes-vous sûr de vouloir confirmer quand même ?',
    force_update_content: 'Êtes-vous sûr de vouloir forcer l’envoi à SAP ?',
    connection_to_station_in_progress: 'Connexion à la station en cours',
    tag_killed: 'Étiquette désactivée',
    tag_deleted: 'Étiquette supprimée',
    write_tag: 'Étiquette écrite avec succès',
    not_ready: 'Non prêt',
    readyf: 'Prêt',
    nfcDecryption: 'Déchiffrement NFC. Veuillez maintenir l’étiquette immobile',
    parcel_success: 'Colis confirmé avec succès',
  },
  placeholder: {
    search_shipment_parcel: 'Rechercher une expédition ou un colis',
    search_product_parcel: 'Rechercher un produit ou un colis',
  },
  confirm: {
    discrepancy_title: 'Colis avec divergences',
    discrepancy_message:
      'Des produits avec des divergences ont été trouvés. Êtes-vous sûr de vouloir confirmer le colis ?',
    confirm_delete_association: 'Êtes-vous sûr de vouloir supprimer cette association d’article ?',
    confirm_delete_operation_title: 'Supprimer {{operation}}',
    confirm_delete_operation: 'Voulez-vous supprimer l’opération ? Tous les articles lus seront perdus.',
    confirm_delete_item_association: 'Êtes-vous sûr de vouloir supprimer cette association d’article ?',
    confirm_kill_tag: 'Êtes-vous sûr de vouloir désactiver l’étiquette {{tag}} ?',
    confirm_operation_title: 'Confirmer {{operation}}',
    confirm_operation_text: 'Voulez-vous confirmer l’opération ?',
    confirm_operation_text_irreversible: 'Voulez-vous confirmer l’opération ? Cette action est irréversible.',
    confirm_operation_text_partial_confirm: 'Envoi de la lecture vers JDE. Êtes-vous sûr de vouloir continuer ?',
    post_confirm_action_title: 'Continuer',
    post_confirm_action_keep_input: 'Opération terminée. Effectuer une autre opération ?',
    post_confirm_action_change_input: 'Opération terminée. Effectuer une autre opération ?',
    confirm_close_asn_title: 'Confirmation de clôture',
    confirm_close_asn_text:
      'Certains colis n’ont pas été reçus. Êtes-vous sûr de vouloir FORCER la clôture de cette expédition ?',
    unpack: 'Colis déjà emballé. Voulez-vous procéder au déballage et à un nouvel emballage ?',
  },
  labels: {
    draft: 'Brouillon',
    ready_to_send: 'Prêt à envoyer',
    send_await: 'En attente d’envoi',
    outbound_error: 'Erreur d’expédition',
    sent: 'Envoyé',
    in_inbound: 'En cours d’entrée',
    receive_await: 'En attente de réception',
    ready_to_receive: 'À recevoir',
    inbound_error: 'Erreur d’entrée',
    received: 'Reçu',
    in_transit: 'En transit',
  },
  serverError: {
    //ZONE_MISMATCH_ERROR: 'L’article ne se trouve pas dans la zone d’origine sélectionnée',
  },
  report_tbox: {
    umg: 'Umg',
    ddt_number: 'DDT N.',
    creation_date: 'Date',
    capi_mdc: 'Capi MDC',
    qt_totale: 'Quantité totale',
    qt_accettata: 'Quantité acceptée',
    qt_scartata: 'Quantité rejetée',
    supplier: 'Fournisseur',
    notes: 'Remarques',
  },
  custom: {
    countTBoxParcel: 'Colis',
    countTBoxDDT: 'DDT',
    BARCODE_READ: 'Lecture du code-barres',
    RFID_START_READING: 'Démarrer la lecture',
    RFID_STOP_READING: 'Arrêter la lecture',
    RESULT_EVENT: 'Événement de résultat',
    TRACE: 'Trace',
    INFO: 'Info',
    WARNING: 'Avertissement',
    ERROR: 'Erreur',
    deleteSession: 'Supprimer le colis',
    deleteSessionMessage: 'Êtes-vous sûr de vouloir supprimer le colis ?',
    deleteList: 'Supprimer le DDT',
    deleteListMessage: 'Êtes-vous sûr de vouloir supprimer le DDT ?',
    WAM: 'SKU',
    sap_warning: 'Avertissement SAP',
    total_list: 'Liste totale',
    total_parcel: 'Colis total',
    order_number: 'Numéro de commande',
    addon: 'Supplément',
    componentType: 'Type de composant',
  },
  imbustatrice: {
    and: ' et ',
    unkwon_tag: 'Étiquette inconnue',
    dynamic_tag: 'Étiquette {{role}}',
    dynamic_tag_missing: 'Étiquette {{role}} manquante',
    too_many_dynamic_tags: 'Trop d’étiquettes {{role}}',
    dynamic_tag_already_associated: 'Étiquette {{role}} déjà associée',
    wrong_tag: 'Mauvaise étiquette',
    missing_tag: 'Étiquette manquante',
  },
  identifier_error: {
    ALREADY_ASSOCIATED_TAG: 'Déjà associée',
    WRONG_QC_STATUS: 'Statut de contrôle qualité incorrect',
    WRONG_QC_SIZE: 'Taille de contrôle qualité incorrecte',
    WRONG_QC_MODEL: 'Modèle de contrôle qualité incorrect',
    MISSING_MANDATORY_QC: 'Contrôle qualité manquant',
    MISSING_PRODUCT_INFO: 'Informations produit manquantes',
    WRONG_TAG: 'Mauvaise étiquette',
    UNKNOWN_TAG: 'Étiquette inconnue',
    WRONG_PRODUCT: 'Mauvais produit',
    MISSING_MANDATORY_TAG: 'Étiquette obligatoire manquante',
    MISSING_OPTIONAL_TAG: 'Étiquette optionnelle manquante',
    EXTRA_TAG: 'Étiquette en trop',
    TAG_MISMATCH: 'Incohérence d’étiquette',
    PRODUCTIONORDERROW_MISMATCH: 'Incohérence de l’association de ligne de commande de production',
    MISSING_SYSTEM_TAG: 'Étiquette système inconnue',
    UNEXPECTED_TAG: 'Étiquette inattendue',
  },
  statuses: {
    RESTORE_VARIANT: 'RESTAURER TYPOLOGIE',
  },
  userSettings: {
    title: 'Paramètres utilisateur',
    autostopAntennaTimeout: 'Arrêt automatique de l’antenne (ms)',
    autostartAntenna: 'Démarrage automatique de l’antenne',
    autoConfirmParcel: 'Confirmation automatique du colis',
  },
}
