import { Component } from 'react'
import { Box, Button, Input, KeyValueRow, Modal, Spacer, Switch } from 'stylewhere/components'
import { T, __ } from 'stylewhere/i18n'
import { AppStore, Router } from 'stylewhere/shared'
import { sleep, __isDev, enabledUtilities } from 'stylewhere/utils'

interface Props {
  onClose: () => void
  visible: boolean
}

interface State {
  admin: boolean
  endpoint: string
}

export class SettingsModal extends Component<Props, State> {
  languages = [
    { label: 'English', value: 'en' },
    { label: 'Italiano', value: 'it' },
    { label: 'Français', value: 'fr' },
  ]
  state: State = { admin: false, endpoint: '' }

  componentDidMount() {
    this._init()
  }

  _init = async () => {
    const endpointUrl = await AppStore.getEndpoint()
    const adminMode = await AppStore.getAdminMode()
    this.setState({ endpoint: endpointUrl, admin: adminMode })
  }

  handleChange = (item: any) => {
    AppStore.changeLanguage(item.value)
    sleep(100).then(() => Router.refresh())
  }

  activateAdmin = () => {
    const { admin } = this.state
    if (!admin) {
      if (prompt('Insert admin password', '') !== '7733') return
    }
    AppStore.setAdminMode(!admin)
    this.setState({ admin: !admin })
  }

  onEmulationClick = async (checked: boolean) => {
    await AppStore.enableEmulation(checked)
    this.forceUpdate()
  }

  onZbpEmulationClick = async (checked: boolean) => {
    await AppStore.enableZbpEmulation(checked)
    this.forceUpdate()
  }

  goUtilities = () => {
    Router.navigate('/utilities', {})
  }

  render() {
    const { onClose, visible } = this.props
    const { admin, endpoint } = this.state
    return (
      <Modal size="lg" onClose={onClose} visible={visible}>
        <Box vcenter row mb={30}>
          <Modal.Title>{__(T.titles.settings)}</Modal.Title>
          <Button
            size="small"
            title={admin ? 'DISABLED ADMIN MODE' : 'ADMIN MODE'}
            onClick={this.activateAdmin}
            variant="secondary"
          />
        </Box>
        {!admin && <KeyValueRow variant="grey" label="Backend" value={endpoint} />}
        {admin && <Input label="Backend" onEnter={AppStore.setEndpoint} defaultValue={endpoint} />}

        <Spacer />

        <KeyValueRow
          variant="grey"
          label={__(T.misc.language)}
          value={this.languages.find((language) => language.value === AppStore.language)?.label}
          optionModal={{
            onSelect: (item) => this.handleChange(item[0]),
            field: 'label',
            options: this.languages ?? [],
            title: __(T.titles.select_language),
            selected: [{ label: this.languages.find((language) => language.value === AppStore.language)?.label }],
          }}
        />

        {admin && enabledUtilities() && (
          <>
            <Spacer />
            <Button size="medium" title="Utilities" onClick={this.goUtilities} variant="secondary" />
          </>
        )}

        {(AppStore.loggedUser?.admin || __isDev || admin) && (
          <>
            <Spacer />
            <Switch
              onChange={this.onEmulationClick}
              check={AppStore.emulation}
              name="emulation"
              label={__(T.misc.enable_workstation_emulation)}
            />
            <Spacer />
            <Switch
              onChange={this.onZbpEmulationClick}
              check={AppStore.zbpEmulation}
              name="zbpEmulation"
              label={__(T.misc.enable_browser_print_emulation)}
            />
          </>
        )}
      </Modal>
    )
  }
}
