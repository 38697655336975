/* eslint-disable */
import { Spinner } from '@chakra-ui/react'
import { datadogRum } from '@datadog/browser-rum'
import Base, { EncodingState } from 'core/pages/Encoding/EncodingEncode'
import { Encodings, Places, Template, Templates, TmrPlace, TmrTag } from 'stylewhere/api'
import { Box, FormSchemaForm, Text } from 'stylewhere/components'
import { EncodingExtensions } from 'stylewhere/extensions'
import { AppStore, FormSchemaData, OperationReadingProps, RfidReader, Router } from 'stylewhere/shared'
import { T, __ } from 'stylewhere/shared/i18n'
import {
  askUserConfirmation,
  getWithUserConfirmationMessage,
  isExpectOnlyMandatoryIdentifiers,
  showToast,
  showToastError,
  __isDev,
} from 'stylewhere/shared/utils'
import ZebraBrowserPrintWrapper from 'zebra-browser-print-wrapper'

export interface BrowserPrintState extends EncodingState {
  availablePrinters: DeviceInfo[]
  selectedPrinterUid?: string
  printTemplate?: Template
}

export interface DeviceStatus {
  isReadyToPrint?: boolean
  errors?: string
}

export type DeviceInfo = DeviceStatus & {
  device: Device
  isDefault: boolean
}

export interface Device {
  name: string
  deviceType: string
  connection: string
  uid: string
  provider: string
  manufacturer: string
  version: number
}

export default class BrowserPrintEncode extends Base<OperationReadingProps<BrowserPrintState>, BrowserPrintState> {
  browserPrint = new ZebraBrowserPrintWrapper()

  state: BrowserPrintState = {
    options: [
      { value: 'associate', label: __(T.misc.associate), active: true },
      { value: 'verify', label: __(T.misc.verify), active: false },
    ],
    formSchemaData: this.getInitFormSchemaData(),
    tagsRead: [],
    processing: false,
    associationStatus: 'TO_BE_READ',
    showPin: false,
    antennaOn: false,
    pin: '',
    forceAssociation: false,
    configuration: [],
    encodingCreateCounter: 0,
    targetTag: '',
    targetTid: '',
    tagToWrite: '',
    serial: '',
    numWriteAttempt: 0,
    starting: false,
    resetFormCounter: 1,
    availablePrinters: [],
  } as any

  async componentDidMount() {
    await super.componentDidMount()
    if (!AppStore.zbpEmulation) {
      await this.fetchPrintTemplate()
      await this.getPrinters()
    }
  }

  onSubmitForm = async (formSchemaData: FormSchemaData) => {
    const { resetFormCounter, selectedPrinterUid, printTemplate } = this.state
    if (!AppStore.zbpEmulation && !selectedPrinterUid) {
      showToastError(__(T.error.error), __(T.error.no_printer_selected), this.isModal)
      return
    }
    if (!AppStore.zbpEmulation && !printTemplate) {
      showToastError(__(T.error.error), __(T.error.no_print_template), this.isModal)
      return
    }

    if (!formSchemaData.product.code) {
      showToastError(__(T.error.error), __(T.identifier_error.MISSING_PRODUCT_INFO), this.isModal)
      return
    }

    if (this.initialType === 'product' && (formSchemaData.product.code as string).trim().length === 0) return

    try {
      const res = await EncodingExtensions.getItemConfiguration(this.operation, formSchemaData)
      this.initializeDataDogSession(formSchemaData)
      res.encodingValidation.identifiers.forEach((element) => {
        if (element.identifierType === 'SIMPLE_ITEM_IDENTIFIER') {
          element._status = 'SKIPPED'
        }
      })
      this.setState(
        {
          encodingValidation: res.encodingValidation,
          encodingValidationResponse: res.encodingValidationResponse,
          counters: res.counters,
          formSchemaData,
        },
        async () => {
          try {
            const computeTagsResponse = await Encodings.computeTags(
              this.operation.id,
              [],
              AppStore.defaultWorkstation!.id,
              this.state.encodingValidationResponse!.item.product.id
            )
            const newEpcId = computeTagsResponse.find((r) => r.identifierType === 'UHF_TAG')
            const newSerialId = computeTagsResponse.find((r) => r.identifierType === 'SIMPLE_ITEM_IDENTIFIER')
            if (!newEpcId || !newSerialId) return await showToastError(__(T.error.error), __(T.error.error))

            if (__isDev) console.log('new Epc', newEpcId.desiredCode)

            const newEncodingValidation = { ...this.state.encodingValidation! }
            newEncodingValidation.identifiers.forEach((element) => {
              if (element.identifierType === 'SIMPLE_ITEM_IDENTIFIER') {
                element.code = newSerialId.desiredCode
                element._status = 'SKIPPED'
              }
            })

            this.setState(
              {
                associationStatus: 'TO_BE_READ',
                targetTag: newEpcId.desiredCode,
                serial: newSerialId.desiredCode,
                encodingValidation: newEncodingValidation,
              },
              async () => {
                if (this.datadogSession) {
                  this.datadogSession.serial = {
                    offsetMs: new Date().getTime() - this.datadogSession!.startTime.getTime(),
                    serialCode: newSerialId.desiredCode,
                  }
                  datadogRum.addAction('serialGenerated', this.datadogSession)
                }
                await this.sendZbpPrint()
                await this.startReader()
              }
            )
          } catch (e) {
            showToastError(e, __(T.error.error))
          }
        }
      )
    } catch (error) {
      this.setState({ resetFormCounter: resetFormCounter + 1 })
      showToastError(error, __(T.error.error), this.isModal)
    }
  }

  call_force_encoding = (write) => {
    const { encodingCreateCounter } = this.state
    this.isEncoding = true
    this.setState({ forceAssociation: true, pin: '', encodingCreateCounter: encodingCreateCounter + 1 }, this.create)
  }

  additionalOnTagReadCheck = (tag: TmrTag) => {
    const { encodingValidation, targetTag } = this.state
    if (encodingValidation && tag.epc && tag.epc !== targetTag) {
      if (
        encodingValidation.identifiers.some(
          (id) => id.identifierType === 'UHF_TAG' && id.code === targetTag && id._status === 'CONFIRMED'
        )
      ) {
        console.log('UHF discard already confirmed', tag.epc)
        return false
      } else {
        const newEncodingValidation = { ...encodingValidation }
        newEncodingValidation.identifiers.forEach((element) => {
          if (element.identifierType === 'UHF_TAG') {
            element._status = 'ERROR'
            element._error = __(T.error.wrong_tag)
          } else if (element.identifierType === 'NFC_TAG' && !element._status) {
            element._status = 'ERROR'
            element._error = __(T.imbustatrice.dynamic_tag_missing, { role: element.role })
          }
        })

        this.setState({ encodingValidation: newEncodingValidation })
        showToastError(__(T.error.wrong_tag), __(T.error.error), this.isModal)
        return false
      }
    }
    return true
  }

  // callback response validate on flow product and order
  onTagReadValidateResponse = async () => {
    const { encodingValidationResponse, encodingCreateCounter } = this.state
    if (encodingValidationResponse) {
      if (encodingValidationResponse.success) {
        // controllo se non devo fare operazioni di scrittura
        if (encodingValidationResponse.operationToPerform === 'NONE') {
          await this.onValidationOperationNone()
        } else {
          this.setState({ encodingCreateCounter: encodingCreateCounter + 1 }, this.create)
        }
      } else {
        // this.startTimerNoReads()
        if (this.checkIfForceCreate('yes')) {
          this.call_force_encoding(true)
        } else if (this.checkIfForceCreate('withUserConfirmation')) {
          const errors = encodingValidationResponse.errors ?? []
          // if only error with code MISSING_OPTIONAL_TAG and isExpectOnlyMandatoryIdentifiers do force create
          if (
            errors.length == 1 &&
            errors[0].errorCode === 'MISSING_OPTIONAL_TAG' &&
            isExpectOnlyMandatoryIdentifiers(this.operation)
          ) {
            this.call_force_encoding(true)
          } else {
            const ok = await askUserConfirmation(
              __(T.misc.attention),
              getWithUserConfirmationMessage(errors, encodingValidationResponse)
            )
            if (ok) {
              this.call_force_encoding(true)
            } else {
              this.clear()
              this.isEncoding = false
            }
          }
        } else {
          this.checkAttachAutomaticReaderStop(encodingValidationResponse)
        }
      }
    }
  }

  beforeChageTab() {
    // extensible
  }

  _changeTab = async () => {
    this.beforeChageTab()
    RfidReader.clear()
    await this.stopAntenna()
    this.timer = setTimeout(() => {
      Router.navigate(
        `/encoding/:opCode/verify`,
        {
          opCode: this.operation.code,
        },
        {
          state: {
            originTemplatePath: this.operation.templatePath,
          },
        }
      )
    }, this.operation.options.antennaTurnWaitingInterval ?? 1000)
  }

  fetchPrintTemplate = async () => {
    if (AppStore.zbpEmulation) return
    if (!this.operation.attributes['zpl-template-code']) {
      showToastError(__(T.error.zplTemplateNotDefined), __(T.error.error), this.isModal)
      return
    }
    try {
      const printTemplate: Template = await Templates.getByCode(this.operation.attributes['zpl-template-code'])
      this.setState({ printTemplate })
    } catch (error: any) {
      showToastError(`Template: ${error.message}`, __(T.error.error), this.isModal)
    }
  }

  getPrinters = async () => {
    if (AppStore.zbpEmulation) return []
    try {
      const defaultPrinter = await this.browserPrint.getDefaultPrinter()
      const availablePrinters: Device[] = await this.browserPrint.getAvailablePrinters()
      let printerToSelect = defaultPrinter
      const lastUsedPrinterUid = await AppStore.getZbpSelectedPrinterUid()
      if (lastUsedPrinterUid && availablePrinters.some((p) => p.uid === lastUsedPrinterUid)) {
        printerToSelect = availablePrinters.find((p) => p.uid === lastUsedPrinterUid)!
      }
      this.browserPrint.setPrinter(printerToSelect)
      const printerToSelectStatus = await this.browserPrint.checkPrinterStatus()
      if (!printerToSelectStatus.isReadyToPrint) {
        // if printer status fetch goes timeout do not show error
        if (!printerToSelectStatus.errors.includes('Error: Unknown Error')) {
          showToastError(`${printerToSelect.name}: ${printerToSelectStatus.errors}`, __(T.error.error), this.isModal)
        }
      }
      this.setState({
        availablePrinters: availablePrinters.map((device) => ({
          device,
          isDefault: device.uid === defaultPrinter.uid,
          isReadyToPrint: device.uid === printerToSelect.uid ? printerToSelectStatus.isReadyToPrint : undefined,
          errors: device.uid === printerToSelect.uid ? printerToSelectStatus.errors : undefined,
        })),
        selectedPrinterUid: printerToSelect.uid,
      })
    } catch (error: any) {
      console.error(error)
      if (error.message.includes('Failed to fetch')) {
        showToastError(__(T.error.zbpNotFound), __(T.error.error), this.isModal)
      } else {
        showToastError(`${error.message}. Please check Zebra Browser Print agent`, __(T.error.error), this.isModal)
      }
      this.setState({ availablePrinters: [], selectedPrinterUid: undefined })
    }
  }

  updateAvailablePrinter = (printerInfo: DeviceInfo, printerStatus: DeviceStatus, andSelect?: boolean) => {
    const { availablePrinters, selectedPrinterUid } = this.state
    const selectedPrinterIsTheDefaultOne =
      availablePrinters.find((p) => p.isDefault)?.device.uid === printerInfo.device.uid
    this.setState({
      availablePrinters: [
        ...availablePrinters
          .filter((p) => p.device.uid !== printerInfo.device.uid)
          .map((p) => ({ ...p, isReadyToPrint: undefined })),
        {
          device: printerInfo.device,
          isDefault: selectedPrinterIsTheDefaultOne,
          isReadyToPrint: printerStatus.isReadyToPrint,
          errors: printerStatus.errors,
        },
      ],
      selectedPrinterUid: andSelect ? printerInfo.device.uid : selectedPrinterUid,
    })
  }

  onPrinterSelected = async (v: DeviceInfo) => {
    try {
      this.browserPrint.setPrinter(v.device)
      const printerStatus = await this.browserPrint.checkPrinterStatus()
      if (!printerStatus.isReadyToPrint) {
        // if printer status fetch goes timeout do not show error
        if (!printerStatus.errors.includes('Error: Unknown Error')) {
          showToastError(`${v.device.name}: ${printerStatus.errors}`, __(T.error.error), this.isModal)
        }
      }
      await AppStore.setZbpSelectedPrinterUid(v.device.uid)
      this.updateAvailablePrinter(v, printerStatus, true)
    } catch (e) {
      showToastError(e, __(T.error.error), this.isModal)
    }
  }

  sendZbpPrint = async () => {
    if (AppStore.zbpEmulation) {
      showToast({
        title: 'Info',
        description: 'Print sent to mocked Browser Printer.',
        status: 'success',
      })
      return
    }
    const { availablePrinters, selectedPrinterUid, printTemplate, targetTag, serial, encodingValidationResponse } =
      this.state
    try {
      const selectedPrinter = availablePrinters.find((p) => p.device.uid === selectedPrinterUid)
      if (!selectedPrinter) return
      if (!encodingValidationResponse || !encodingValidationResponse.item.product) {
        showToastError(__(T.identifier_error.MISSING_PRODUCT_INFO), __(T.error.error), this.isModal)
        return
      }
      if (!printTemplate) {
        showToastError(__(T.error.error), __(T.error.no_print_template), this.isModal)
        return
      }
      let printerStatus = await this.browserPrint.checkPrinterStatus()
      if (!printerStatus.isReadyToPrint) {
        showToastError(`${selectedPrinter.device.name}: ${printerStatus.errors}`, __(T.error.error), this.isModal)
      } else {
        const product = encodingValidationResponse.item.product
        const ws = await AppStore.getDefaultWorkstation()
        if (!ws) return
        const wsPlace: TmrPlace = await Places.get(ws.placeId)

        const dataMatrix = `0_${serial}_0010_${product.code}__${wsPlace.code}_${wsPlace.countryCode ?? ''}__`
        const zpl = printTemplate.templateBody
          .replaceAll('[EPC]', targetTag)
          .replaceAll('[SHORTSKU]', product.code)
          .replaceAll('[PRODUCTNAME]', product.description ?? '')
          .replaceAll('[SERIAL_NUMBER]', serial)
          .replaceAll('[DATAMATRIX]', dataMatrix)
        await this.browserPrint.print(zpl)
        printerStatus = await this.browserPrint.checkPrinterStatus()
        if (!printerStatus.isReadyToPrint) {
          showToastError(`${selectedPrinter.device.name}: ${printerStatus.errors}`, __(T.error.error), this.isModal)
        }
      }
      this.updateAvailablePrinter(selectedPrinter, printerStatus)
    } catch (e) {
      showToastError(e, __(T.error.error), this.isModal)
    }
  }

  getSelectDeviceLabel = (deviceInfo?: DeviceInfo) => {
    if (!deviceInfo) return ''
    return `${deviceInfo.device.name}${deviceInfo.isDefault ? ' (Default) ' : ''}${
      deviceInfo.isReadyToPrint === true
        ? ` - ${__(T.messages.readyf)}`
        : deviceInfo.isReadyToPrint === false
        ? ` - ${__(T.messages.not_ready)}`
        : ''
    }`
  }

  getCustomHeader = () => {
    const { availablePrinters, selectedPrinterUid } = this.state
    return (
      <Box style={{ marginLeft: 20, marginRight: 20, justifyContent: 'center' }}>
        {AppStore.zbpEmulation ? (
          <Text>MOCKED PRINTER</Text>
        ) : availablePrinters && availablePrinters.length > 0 ? (
          <FormSchemaForm
            key="printer"
            flex
            style={{ minWidth: 340 }}
            disabledSpacerAfterFields
            schema={[
              {
                type: 'select',
                name: 'printer',
                label: __(T.misc.printer),
                required: true,
                options: availablePrinters.map((p) => ({
                  value: p.device.uid,
                  label: this.getSelectDeviceLabel(p),
                })),
                defaultValue: {
                  value: availablePrinters.find((p) => p.device.uid === selectedPrinterUid)?.device.uid,
                  label: this.getSelectDeviceLabel(availablePrinters.find((p) => p.device.uid === selectedPrinterUid)),
                },
                onChange: (selected) => {
                  if (!selected) return this.setState({ selectedPrinterUid: undefined })
                  const selectedPrinter = availablePrinters.find((p) => p.device.uid === selected.value)
                  if (!selectedPrinter) return
                  this.onPrinterSelected(selectedPrinter)
                },
              },
            ]}
            onSubmit={(data: any) => {
              this.onPrinterSelected(data)
            }}
          />
        ) : (
          <Spinner width={10} height={10} />
        )}
      </Box>
    )
  }

  getDisableRetry() {
    return true
  }

  getDisableInput() {
    return !AppStore.zbpEmulation && !this.state.selectedPrinterUid
  }

  getProcessingMessage() {
    return __(this.state.processing === 'nfcDecryption' ? T.messages.nfcDecryption : T.messages.operation_in_progress)
  }

  render() {
    return super.render()
  }
}
