import { Component } from 'react'
import { RouteComponentProps, StaticContext } from 'react-router'
import { cloneDeep as _cloneDeep, get as _get, set as _set } from 'lodash'
import {
  Asn,
  DecodedItem,
  ShippingParcel,
  Shippings,
  ShippingTag,
  ShippingSelectData,
  api,
  ResourcePage,
} from 'stylewhere/api'
import {
  FullLoadingLayer,
  OperationStart,
  Page,
  ShippingStartInput,
  ShippingStartListAndInput,
} from 'stylewhere/components'
import { ShippingExtensions } from 'stylewhere/extensions'
import { T, __ } from 'stylewhere/i18n'
import type { Routes } from 'stylewhere/pages'
import {
  FormSchemaData,
  getDataFromSchema,
  OperationReadingProvider,
  OperationReadingState,
  RemoteOperation,
  RfidReader,
  Router,
  ShippingOperationConfig,
  setArgsParams,
  AppStore,
} from 'stylewhere/shared'
import { CustomError } from 'stylewhere/shared/errors'
import { PackingOperationConfig } from 'stylewhere/shared/RemoteOperation'
import { askUserConfirmation, isModalError, showToastError } from 'stylewhere/utils'
import { ShippingParcelStartParams } from './ShippingParcelStart'

interface Params {
  opCode: string
}

export interface ShippingStartState {
  skipAsnSelection?: boolean
  items: DecodedItem<string>[]
  renderIteration: number
  loading: boolean
}

export default class ShippingStart<
  P extends RouteComponentProps<Params, StaticContext, OperationReadingState>
> extends Component<P> {
  submitPath: Routes = '/shipping/:opCode/reading'
  asnParcelPath: Routes = '/shipping/:opCode/asn/:asnId'
  selfPath: Routes = '/shipping/:opCode'
  operation = RemoteOperation.getOperationConfig<ShippingOperationConfig | PackingOperationConfig>(
    Router.getMatchParams(this.props).opCode
  )
  checkingIdentifier = false
  isModal = false

  state: ShippingStartState = {
    items: [],
    renderIteration: 0,
    loading: false,
  }
  locationState = Router.getLocationState(this.props)

  tags: ShippingTag[] = []

  mustSelect() {
    return (
      this.operation &&
      ['INBOUND', 'OUTBOUND'].includes(this.operation.type) &&
      ((this.operation.shippingMode === 'parcelByParcel' &&
        (this.operation.showListOnStart || this.operation.canCreateParcels)) ||
        (this.operation.shippingMode === 'shipment' &&
          (this.operation.showListOnStart || this.operation.canCreateShipments))) &&
      !this.state.skipAsnSelection
    )
  }

  setSkipAsnSelection = () => {
    this.setState({ skipAsnSelection: false })
  }

  isParcelByParcel = () => {
    return this.operation.shippingMode === 'parcelByParcel'
  }

  isAsnSelectMode() {
    return this.operation.showListOnStart || this.operation.canCreateShipments || this.operation.canCreateParcels
  }

  async componentDidMount() {
    this.isModal = isModalError(this.operation)
    if (this.operation.startWithRfidSearch) {
      RfidReader.setBatchInterval(this.operation.batchInterval)
      RfidReader.setBatchIntervalTagCount(this.operation.batchIntervalTagCount)
      RfidReader.setBatchIntervalTime(this.operation.shippingReadTimeout ?? this.operation.batchIntervalTime)
      RfidReader.setAutomaticStop(this.operation.autostopAntennaTimeout > 0)
      RfidReader.setAutomaticStopTime(this.operation.autostopAntennaTimeout)
      await OperationReadingProvider.init(
        this.operation,
        this.locationState,
        this.goBack,
        this.setRfidReaderDecode,
        true
      )
    }
  }

  goBack = () => {
    if (this.isAsnSelectMode()) {
      this.setSkipAsnSelection()
    } else {
      Router.navigate('/')
    }
  }

  setRfidReaderDecode = () => {
    RfidReader.setDecodeFunction(async (epcs) => {
      return epcs.reduce((acc, epc) => {
        acc[epc] = { epc }
        return acc
      }, {})
    })
    RfidReader.setOnDecodedItemCallback(this.onDecodedItemCallback, this.getDecodeRequest())
  }

  getDecodeRequest = () => {
    return {
      url: Shippings.batchValidateEndpoint(this.operation),
      payload: {
        operationId: this.operation.id,
      },
    }
  }

  parcelByIdentifierError = () => {
    const tagsToRemove = this.tags.map((tag) => tag.code)
    RfidReader.removeTags(tagsToRemove)
    this.tags = []
    this.checkingIdentifier = false
    this.setState({ loading: false })
  }

  onDecodedItemCallback = async (itemMapFromReader: { [tagCode: string]: DecodedItem }) => {
    if (this.checkingIdentifier) return
    this.checkingIdentifier = true
    RfidReader.stop()
    RfidReader.clear()
    this.call(itemMapFromReader)
  }

  call = async (itemMapFromReader) => {
    this.setState({ loading: true })
    let parcelCode
    const tags = Object.keys(itemMapFromReader).map((code) => ({ epc: code }))
    this.tags = [...this.tags, ...tags.map((t) => ({ code: t.epc, tid: '' }))]

    let parcelByIdentifier: ShippingParcel | undefined = undefined
    try {
      parcelByIdentifier = await Shippings.parcelByIdentifier(this.operation, this.tags)
    } catch (error) {
      this.parcelByIdentifierError()
      showToastError(error, __(T.error.error), this.isModal)
      this.checkingIdentifier = false
      return
    }

    let payload: any = undefined
    if (parcelByIdentifier && parcelByIdentifier?.code) {
      parcelCode = parcelByIdentifier.code || ''
      payload = {
        parcelCode,
        attributes: parcelByIdentifier.attributes || {},
        operationId: this.operation.id,
        asn: parcelByIdentifier.asn || {},
      }
    }

    try {
      if (parcelByIdentifier && parcelByIdentifier?.code) {
        const parcel = await Shippings.startParcel(this.operation, payload)
        const formData: Record<string, unknown> = {}
        const state: Record<string, any> = {
          formData,
          parcel,
          asn: parcelByIdentifier.asn,
          tags: this.tags.map((t) => ({ epc: t.code })),
        }
        if (this.isAsnSelectMode()) {
          state.formData.parcelCode = parcelByIdentifier.code
        }
        this.setState({ loading: false })
        Router.navigate(this.submitPath, { opCode: this.operation.code }, { state })
      } else {
        this.parcelByIdentifierError()
        showToastError(__(T.error.no_parcel_found_by_identifier), __(T.error.search_error), this.isModal)
      }
    } catch (error) {
      this.parcelByIdentifierError()
      this.checkingIdentifier = false
      if (
        await ShippingExtensions.checkPackingUnpack(
          error as any,
          parcelCode,
          this.operation,
          () => this.onDecodedItemCallback(itemMapFromReader),
          () => this.setState({ renderIteration: this.state.renderIteration + 1 })
        )
      )
        return
      if (error instanceof CustomError) {
        showToastError(`${CustomError.interpolateMessage(error.message, payload)}. [${error.errorCode}]`)
        return
      }
      showToastError(error, __(T.error.error), this.isModal)
    }
    this.checkingIdentifier = false
  }

  getInitialValue = async (schema, asn, object, initialValues) => {
    schema.map(async (e) => {
      let value = _get(object, e.name)
      if (!value) {
        value = _get(asn, e.name)
      }

      if (value) {
        if (e.type === 'autocomplete' && e.endpoint && typeof value === 'string') {
          try {
            const endpoint = setArgsParams(e.endpoint)
            const result = await api.get<ResourcePage<any>>(endpoint, {
              size: 1,
              ids: value,
            })
            if (result && result.data && result.data.content && result.data.content.length === 1) {
              _set(initialValues, e.name, result.data.content[0])
            }
          } catch {
            console.log('error')
          }
        } else {
          _set(initialValues, e.name, value)
        }
      } else if (e.type === 'hidden' && e.defaultValue && e.defaultValue === ':placeId') {
        _set(initialValues, e.name, AppStore.defaultWorkstation?.placeId)
      }
    })
    return initialValues
  }

  onSelectParcel = async (selected: ShippingSelectData) => {
    const selectedParcel = selected as ShippingParcel
    const schemaParcel = ShippingExtensions.formSchema(this.operation, undefined, ['', 'parcel'])

    const formData: any = {
      parcelCode: selectedParcel.code,
    }
    if (schemaParcel) {
      schemaParcel.map((s) => {
        const value = _get(selectedParcel, s.name)
        if (value) {
          _set(formData, s.name, value)
        }
      })
    }

    if (selectedParcel.asn) {
      formData.asn = {
        code: selectedParcel.asn.code,
      }

      if (
        selectedParcel.asn.destinationPlace &&
        selectedParcel.asn.destinationPlace !== null &&
        selectedParcel.asn.destinationPlace.id
      ) {
        formData.asn.destinationPlaceId = selectedParcel.asn.destinationPlace
      }

      if (
        selectedParcel.asn.originPlace &&
        selectedParcel.asn.originPlace !== null &&
        selectedParcel.asn.originPlace.id
      ) {
        formData.asn.originPlaceId = selectedParcel.asn.originPlace
      }
    }

    const formattedData = await this.getInitialValue(
      schemaParcel,
      selectedParcel.asn,
      selectedParcel,
      _cloneDeep(formData)
    )
    // override payload to send direct id for place if present
    const payloadStartParcel = formattedData.asn
      ? {
          ...formattedData,
          asn: {
            code: formattedData.asn.code,
            destinationPlaceId:
              formattedData.asn.destinationPlaceId && formattedData.asn.destinationPlaceId !== null && formattedData.asn.destinationPlaceId.id
                ? formattedData.asn.destinationPlaceId.id
                : undefined,
            originPlaceId:
              formattedData.asn.originPlaceId && formattedData.asn.originPlaceId !== null && formattedData.asn.originPlaceId.id
                ? formattedData.asn.originPlaceId.id
                : undefined,
          },
        }
      : formattedData
    try {
      const parcel = await Shippings.startParcel(this.operation, payloadStartParcel, this.operation.startParcelWithAsn)
      Router.navigate(this.submitPath, { opCode: this.operation.code }, { state: { formData: formattedData, parcel } })
    } catch (err) {
      showToastError(err, 'Start Parcel Error', this.isModal)
    }
  }

  onSubmit = async (formData, operation: ShippingOperationConfig, schema) => {
    let parcelCode
    const payloadStartParcel: any = getDataFromSchema(formData, schema)
    try {
      parcelCode = payloadStartParcel.parcelCode
      const parcel = await Shippings.startParcel(operation, payloadStartParcel, operation.startParcelWithAsn)
      // set formData.parcelCode as parcel.code if not exist or if is empty
      if ((!formData.parcelCode || formData.parcelCode === '') && parcel.code) formData.parcelCode = parcel.code
      Router.navigate(this.submitPath, { opCode: operation.code }, { state: { formData, parcel } })
    } catch (err) {
      if (
        await ShippingExtensions.checkPackingUnpack(
          err as any,
          parcelCode,
          this.operation,
          () => this.onSubmit(formData, operation, schema),
          () => this.setState({ renderIteration: this.state.renderIteration + 1 })
        )
      )
        return
      if (err instanceof CustomError) {
        showToastError(`${CustomError.interpolateMessage(err.message, payloadStartParcel)}. [${err.errorCode}]`)
        return
      }
      showToastError(err, 'Start Parcel Error', this.isModal)
    }
  }

  navigateToAsn = (asnId) => {
    const nextRouteParams: ShippingParcelStartParams = {
      opCode: this.operation.code,
      asnId,
    }
    Router.navigate(this.asnParcelPath, nextRouteParams)
  }

  onSelectAsn = async (selected: ShippingSelectData) => {
    try {
      const asn = selected as Asn
      if (asn && asn.id) {
        const formData = {
          shipmentCode: asn.code,
          originPlaceId:
            asn.originPlaceId && asn.originPlaceId !== null ? asn.originPlaceId : AppStore.defaultWorkstation?.placeId,
          destinationPlaceId:
            asn.destinationPlaceId && asn.destinationPlaceId !== null
              ? asn.destinationPlaceId
              : AppStore.defaultWorkstation?.placeId,
          attributes: asn.attributes ?? {},
        }
        await Shippings.startAsn(this.operation, formData)
        this.navigateToAsn(asn.id)
      } else {
        throw new Error(__(T.error.submit_asn))
      }
    } catch (err) {
      showToastError(err, 'Start ASN Error', this.isModal)
    }
  }

  onCreateAsn = async (formData: FormSchemaData, operation: ShippingOperationConfig, schema) => {
    try {
      if (formData && formData.shipmentCode) {
        const newAsn = await Shippings.startAsn(operation, formData)
        const asnId = newAsn.id
        if (asnId) {
          this.navigateToAsn(asnId)
        } else {
          throw new Error(__(T.error.submit_asn))
        }
      }
    } catch (err) {
      showToastError(err, 'Start ASN Error', this.isModal)
    }
  }

  onReadParcel = () => {
    this.setState({ skipAsnSelection: true })
  }

  public render() {
    const isParcel = this.isParcelByParcel()
    if (this.mustSelect()) {
      if (this.operation.showListOnStart) {
        return (
          <ShippingStartListAndInput
            operation={this.operation}
            onSelect={isParcel ? this.onSelectParcel : this.onSelectAsn}
            onClickReadParcel={this.onReadParcel}
            onCreate={isParcel ? this.onSubmit : this.onCreateAsn}
            shipment={!isParcel}
          />
        )
      } else if (this.operation.canCreateShipments || this.operation.canCreateParcels) {
        return (
          <Page title={this.operation.description} onBackPress={() => Router.navigate('/')} enableEmulation={false}>
            <ShippingStartInput
              operation={this.operation}
              onCreate={isParcel ? this.onSubmit : this.onCreateAsn}
              shipment={!isParcel}
            />
          </Page>
        )
      }
    }

    const onBackPress =
      this.isAsnSelectMode() && this.operation.shippingMode === 'shipment' ? this.setSkipAsnSelection : undefined
    return (
      <>
        <OperationStart
          key={this.state.renderIteration}
          onBackPress={onBackPress}
          startWithRfidSearch={this.operation.startWithRfidSearch}
          submitPath={this.submitPath}
          extensions={ShippingExtensions}
          onSubmit={this.onSubmit}
          setOnDecodedItemCallback={this.operation.startWithRfidSearch ? this.setRfidReaderDecode : undefined}
        />
        {this.state.loading && <FullLoadingLayer />}
      </>
    )
  }
}
