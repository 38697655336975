import { createStandaloneToast, UseToastOptions } from '@chakra-ui/react'
import { get as _get, isNumber as _isNumber, capitalize as _capitalize } from 'lodash'
import { v4 as generateUid } from 'uuid'
import { ModalType } from 'stylewhere/types'
import {
  ShipmentParcel,
  DecodedItemSorting,
  DecodedItem,
  ProductInfoField,
  TmrTag,
  TmrProduct as StwProduct,
  ShippingParcelChecklist,
  ConfirmModalParams,
  SortingGroupRead,
  Asn,
  ShippingParcel,
} from 'stylewhere/api'
import {
  AppStore,
  Sounds,
  setFieldValue,
  FormSchemaField,
  ShippingOperationConfig,
  OperationReadingProvider,
} from 'stylewhere/shared'
import { __, T } from 'stylewhere/shared/i18n'

const toast = createStandaloneToast()

export const sleep = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function uuid() {
  return generateUid()
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const __isDev = process.env.NODE_ENV !== 'production'

interface ToastOptions extends UseToastOptions {
  sound?: boolean
  status: Exclude<UseToastOptions['status'], undefined>
}

export const showToast = ({
  sound = false,
  position = 'bottom-right',
  duration = 3000,
  status,
  isClosable = true,
  ...options
}: ToastOptions) => {
  switch (status) {
    case 'error':
      if (sound) Sounds.error()
      duration = 8000
      break
    case 'warning':
      if (sound) Sounds.error()
      duration = 4000
      break
    case 'success':
      if (sound) Sounds.success()
      break
  }
  return toast({ status, position, duration, isClosable, ...options })
}

export function openFeedbackModal(
  title: string,
  message: string,
  onModalClose?: () => void,
  btnCloseModal?
): Promise<boolean> {
  return new Promise((resolve) => {
    AppStore.showFeedbackModal(title, message, () => (onModalClose ? onModalClose() : resolve(false)), btnCloseModal)
  })
}

export function hideFeedbackModal() {
  AppStore.hideFeedbackModal()
}

export const showToastError = (
  err: any,
  title: string = __(T.error.error),
  modal = false,
  onModalClose?: () => void,
  btnCloseModal = __(T.misc.ok)
) => {
  if (modal) {
    let message = 'Unknown error'
    if (err instanceof Error) {
      message = err.message ? err.message : 'Unknown error'
    } else if (typeof err === 'string') {
      message = err
    }
    return openFeedbackModal(title, message, onModalClose, btnCloseModal)
  }
  if (err instanceof Error) {
    return showToast({
      title,
      description: err.message ? err.message : 'Unknown error',
      status: 'error',
    })
  }
  if (typeof err === 'string') {
    return showToast({
      title,
      description: err,
      status: 'error',
    })
  }
  return showToast({
    title,
    description: 'Generic',
    status: 'error',
  })
}

export const getCheckListTypes = (shippingDetails: ShipmentParcel[]) => {
  const expectedItems = shippingDetails
    .map((detail) => detail.expectedCounter.items)
    .reduce((acc, curr) => acc + curr, 0)
  const expectedTags = shippingDetails.map((detail) => detail.expectedCounter.tags).reduce((acc, curr) => acc + curr, 0)
  const expectedUpcs = shippingDetails.map((detail) => detail.expectedCounter.upcs).reduce((acc, curr) => acc + curr, 0)
  const checklistTypes: ('ITEMS' | 'TAGS' | 'UPCS')[] = []
  if (expectedItems > 0) {
    checklistTypes.push('ITEMS')
  }
  if (expectedTags > 0) {
    checklistTypes.push('TAGS')
  }
  if (expectedUpcs > 0) {
    checklistTypes.push('UPCS')
  }
  return checklistTypes
}

export function askUserConfirmation(
  title: string,
  message: string,
  labelClose?: string,
  labelConfirm?: string,
  /** product code, if present show a product information box inside the confirmation modal */
  showDetailForProduct?: ConfirmModalParams['showDetailForProduct']
): Promise<boolean> {
  return new Promise((resolve) => {
    AppStore.showConfirmModal(
      title,
      message,
      () => resolve(true),
      () => resolve(false),
      labelClose,
      labelConfirm,
      showDetailForProduct
    )
  })
}

export function hideConfirmModal() {
  AppStore.hideConfirmModal()
}

export function openModal(modal: ModalType) {
  AppStore.openModal?.({ id: (+new Date()).toString(), visible: true, ...modal })
}

export function closeModal(id: string) {
  AppStore.closeModal?.(id)
}

export function truncateString(text = '', maxLength: number) {
  if (!text || text.length < maxLength) return text

  return `${text.substring(0, maxLength)}..`
}

function _getDate(timestamp?: number | string, addHourMinutes = false) {
  if (!timestamp) return undefined

  const twoDigits = (s) => `0${s}`.slice(-2)

  const date = new Date(timestamp)
  let result = `${twoDigits(date.getDate())}/${twoDigits(date.getMonth() + 1)}/${date.getFullYear()}`
  if (addHourMinutes) {
    result = `${result} ${twoDigits(date.getHours())}:${twoDigits(date.getMinutes())}`
  }
  return result
}

export function getDatetime(timestamp?: number | string) {
  return _getDate(timestamp, true)
}

export function getDate(timestamp?: number | string) {
  return _getDate(timestamp)
}

export function getListFromCommaSeparatedString(text?: string) {
  return text ? text.replace(/\s/g, '').split(',') : []
}

export const getField = (object: any, path: string) => _get(object, path)

export const isNumber = (number: any) => _isNumber(number)

export const capitalize = (str: string) => _capitalize(str)

export const filterIgnoredItems = (itm) => itm.item?.status !== 'ignored' && itm.item?.status !== 'ignoredWithReason'

export const filterIgnoredAndErrorItems = (itm) =>
  itm.item?.status !== 'error' && itm.item?.status !== 'ignored' && itm.item?.status !== 'ignoredWithReason'

export const filterErrorItems = (itm: DecodedItem) => itm.item?.status === 'error'

export const getCustomTranslation = (key?: string) => {
  if (!key) return ''
  return __(T.custom[key]) || key
}

export function __CAPITALIZE(txt) {
  const arr = txt.split(' ')
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase()
  }
  return arr.join(' ')
}

export const getEpc = (item: DecodedItemSorting['item']) => {
  return item?.identifiers.find(({ type }) => type === 'UHF_TAG')?.code ?? 'n/a'
}

export const getSerial = (item?: DecodedItemSorting['item']) => {
  return (
    item?.identifiers.find(({ type, role }) => type === 'SIMPLE_ITEM_IDENTIFIER' && role === 'serial')?.code ?? 'n/a'
  )
}

export const getSimulateProductCode = () => {
  return '8057201490613'
}

export const getSimulateEpcs = () => {
  return ['3035EBC5C4314F8000D6CF74', '3035EBC5C4314F8000D6CF73']
}

export const getConveyorItemConfiguration = () => {
  return { useProductCode: true, hideIdentifiers: true, hideCqStatus: true }
}

export const sortGroupeditemList = (a: DecodedItem) => {
  if (a.item?.__unexpected) return -1
  return 0
}

export const sortGroupeditemListStatus = (a, b) => {
  if (a.item && a.item.status && a.item.status === 'error') return -1
  if (b.item && b.item.status && b.item.status === 'error') return 1
  if (a.item && a.item.status && a.item.status === 'warning') return -1
  if (b.item && b.item.status && b.item.status === 'warning') return 1
  if (a.item && a.item.status && a.item.status === 'ignored') return -1
  if (b.item && b.item.status && b.item.status === 'ignored') return 1
  return 0
}

export const getImbustatriceProductError = (product) => {
  let str = ''
  if (product) {
    str = product.code
    if (product.material && product.material.value) str += ' / ' + product.material.value
    if (product.size && product.size.value) str += ' / ' + product.size.value
    if (product.color && product.color.value) str += ' / ' + product.color.value
    str += ' / '
  }
  return str
}

export const CONVEYOR_MIN_WIDTH_SCREEN = 800
export const KEEP_ALIVE_TIME = 1000 * 30 // 30 secondi
export const KEEP_ALIVE_CONTROL_TIME = KEEP_ALIVE_TIME * 2 // 1 minuto dopo l'ultimo keep alive ricevuto
export const RETRY_SSE_TIME = KEEP_ALIVE_TIME * 4 // ogni 2 minuti dopo lo scadere del KEEP_ALIVE_CONTROL_TIME prova a riallacciare sse
export const MAX_LOG_ERROR = 50
export const ERROR_DATE_FORMAT = 'HH:mm:ss'

export const checkQcStatus = () => {
  return false
}

export const isQcStatusProduct = (item) => {
  let cqStatus = 'KO'
  if (item && item.attributes && item.attributes.qcStatus && item.attributes.qcStatus !== 'LOADING') {
    cqStatus = item.attributes.qcStatus
  }
  return cqStatus === 'OK'
}

export const checkSelfUserAttribute = (code) => {
  return true
}

export const checkItemDecodedStatuses = (statuses, accepted) => {
  return statuses && Array.isArray(statuses) && statuses.length > 0 && accepted.includes(statuses[0])
}

export const getFormDataDescription = (formData) => {
  if (formData.note) {
    return formData.note
  } else if (formData.description) {
    return formData.description
  }
  return ''
}

export const getFieldWithAutocomplete = (schema) => {
  const field = schema.find((element) => {
    return element.autocompleteFields
  })
  return field
}

export const checkAutocompleteResult = (field, result, schema, formData) => {
  const res = {
    error: undefined,
    formData: undefined,
  }
  let objField, val
  for (let a = 0; a < field.autocompleteFields.length; a++) {
    objField = schema.find((element) => {
      return element.name === (field && field.autocompleteFields ? field.autocompleteFields[a].name : '')
    })
    if (objField) {
      val = _get(result, field.autocompleteFields[a].field)
      if (val !== undefined) {
        setFieldValue(val, formData, objField as FormSchemaField)
      } else if (!objField.required) {
        setFieldValue(objField.defaultValue, formData, objField as FormSchemaField)
      } else {
        res.error = objField
      }
    } else {
      res.error = objField
    }
  }
  res.formData = formData
  return res
}

export const getProductCode = (product) => {
  if (product?.product) return product.product?.code || 'N/A'
  return product?.code ? product.code : 'N/A'
}

export const showSessionWarning = (stock_type, cqCount, option) => {
  if (option) {
    return stock_type === 'Senza Controllo Qualità' && cqCount === 0
  }
  return false
}

export const blockList = (stock_type, cqCount, option) => {
  if (option) {
    return stock_type === 'Senza Controllo Qualità' && cqCount === 0
  }
  return false
}

export const enabledWamGroupedProduct = () => {
  return false
}

export const getChecklistItemsCount = (
  items: DecodedItem<string>[],
  operation: ShippingOperationConfig,
  extension: any,
  checklist?: ShippingParcelChecklist | null,
  products?: { [upc: string]: StwProduct }
) => {
  const counter = {
    detecteds: 0,
    expecteds: 0,
    unexpecteds: 0,
    overQty: 0,
    missings: 0,
  }
  const filterItems = items.filter(filterIgnoredAndErrorItems)
  if (operation.hasChecklist && checklist && checklist !== null) {
    const parcelType = checklist ? checklist.type : 'CHECKLIST_BY_ITEM'
    const grouped = OperationReadingProvider.groupItems(
      items,
      parcelType === 'CHECKLIST_BY_PRODUCT',
      operation,
      extension,
      checklist,
      products
    )
    switch (parcelType) {
      case 'CHECKLIST_BY_ITEM':
        for (let g = 0; g < grouped.length; g++) {
          counter.detecteds += grouped[g].detected
          counter.expecteds += grouped[g].expected
          counter.unexpecteds += grouped[g].unexpected
          if (grouped[g].added) {
            counter.overQty +=
              grouped[g].detected - grouped[g].unexpected > grouped[g].expected
                ? grouped[g].detected - grouped[g].unexpected - grouped[g].expected
                : 0
          } else {
            counter.overQty += grouped[g].detected > grouped[g].expected ? grouped[g].detected - grouped[g].expected : 0
          }
          counter.missings += grouped[g].expected > grouped[g].detected ? grouped[g].expected - grouped[g].detected : 0
        }
        break
      case 'CHECKLIST_BY_PRODUCT':
        for (let g = 0; g < grouped.length; g++) {
          counter.detecteds += grouped[g].detected
          counter.expecteds += grouped[g].expected
          counter.unexpecteds += grouped[g].unexpected
          if (grouped[g].added) {
            counter.overQty +=
              grouped[g].detected - grouped[g].unexpected > grouped[g].expected
                ? grouped[g].detected - grouped[g].unexpected - grouped[g].expected
                : 0
          } else {
            counter.overQty += grouped[g].detected > grouped[g].expected ? grouped[g].detected - grouped[g].expected : 0
          }
          counter.missings += grouped[g].expected > grouped[g].detected ? grouped[g].expected - grouped[g].detected : 0
        }
        break
    }
  } else {
    counter.detecteds = filterItems.length
  }
  return counter
}

export const validateChecklistReadings = (
  items: DecodedItem<string>[],
  operationConfig: ShippingOperationConfig,
  extension?: any,
  checklist?: ShippingParcelChecklist | null,
  products?: { [upc: string]: StwProduct }
) => {
  const result: {
    validationResult: 'OK' | 'WARNING' | 'KO'
    message: string
  } = { validationResult: 'OK', message: '' }

  const tempMessages: string[] = []

  const { missings, overQty, unexpecteds } = getChecklistItemsCount(
    items,
    operationConfig,
    extension,
    checklist,
    products
  )
  if (missings === 0 && overQty === 0 && unexpecteds === 0) return result

  if (missings > 0) {
    if (operationConfig.canConfirmWithMissing === 'no') result.validationResult = 'KO'
    else if (operationConfig.canConfirmWithMissing === 'withWarning') result.validationResult = 'WARNING'
    tempMessages.push(__(T.messages.checklist_missings))
  }
  if (overQty > 0) {
    if (result.validationResult !== 'KO') {
      if (operationConfig.canConfirmWithOverQuantity === 'no') result.validationResult = 'KO'
      else if (operationConfig.canConfirmWithOverQuantity === 'withWarning') result.validationResult = 'WARNING'
    }
    tempMessages.push(__(T.messages.checklist_overqty))
  }
  if (unexpecteds > 0) {
    if (result.validationResult !== 'KO') {
      if (operationConfig.canConfirmWithUnexpected === 'no') result.validationResult = 'KO'
      else if (operationConfig.canConfirmWithUnexpected === 'withWarning') result.validationResult = 'WARNING'
    }
    tempMessages.push(__(T.messages.checklist_unexpecteds))
  }
  if (tempMessages.length > 0) {
    result.message = `${__(T.messages.checklist_there_are)} ${tempMessages.join(', ')}.`
  }
  if (result.validationResult === 'WARNING') result.message += ` ${__(T.messages.checklist_confirm_mismatch_question)}`
  return result
}

export const getEncodingProductFields = () => {
  const productFields: ProductInfoField[] = [
    { path: 'style.value', label: __(T.misc.style) },
    { path: 'material.value', label: __(T.misc.material) },
    { path: 'color.value', label: __(T.misc.color) },
    { path: 'size.value', label: __(T.misc.size) },
  ]
  return productFields
}

export const isRfidAndSerial = (operation) => {
  const scanIdentifier =
    operation.options && operation.options.scanIdentifier ? operation.options.scanIdentifier : 'rfid'
  return scanIdentifier === 'rfidAndSerial'
}

export const isSkipValidation = (operation) => {
  return !!operation.options && operation.options.skipValidation === 'true'
}

export const enabledDisassociateItemTag = () => {
  return true
}

export const enabledUtilities = () => {
  return true
}

export const encodingComputeTagsEntityCode = (response, initialType, formSchemaData) => {
  if (initialType === 'wam') return formSchemaData.wam || ''
  return response && response.item && response.item.product ? response.item.product.code : ''
}

export const getInitialType = (operation) => {
  let type = operation.options && operation.options.initialType ? operation.options.initialType : 'product'
  if (!enabledWamGroupedProduct() && type === 'wam') {
    type = 'product'
  }
  return type
}

export const getEncodingBlockedErrors = () => {
  return ['WRONG_PRODUCTION_ORDER', 'WRONG_PLACE', 'PRODUCTION_ORDER_ROW_CREATION_ERROR']
}

export const BLOCKED_ERRORS = ['EXTRA_TAG']
export const MAX_WRITE_ATTEMPT = 3
export const MAX_KILL_TAG_ATTEMPT = 3

export const isExpectOnlyMandatoryIdentifiers = (operation) => {
  return operation.options && operation.options.expectOnlyMandatoryIdentifiers
    ? operation.options.expectOnlyMandatoryIdentifiers === 'true'
    : false
}

export const getCanForceAssocation = (operation) => {
  return operation.options && operation.options.canForceAssociation ? operation.options.canForceAssociation : 'no'
}

export const getUhfTagToWriteRegex = (operation) => {
  return operation.options && operation.options.uhfTagToWriteRegex ? operation.options.uhfTagToWriteRegex : ''
}

export const getEnableUhfWrite = (operation) => {
  return operation.options && operation.options.enableUhfWrite ? operation.options.enableUhfWrite : 'no'
}

export const getAskUserInputEveryTime = (operation) => {
  const askUserInputEveryTime =
    operation.options && operation.options.askUserInputEveryTime ? operation.options.askUserInputEveryTime : 'false'
  return askUserInputEveryTime === 'true'
}

export const getTagType = (tag: TmrTag) => {
  let identifierType = 'UHF_TAG'
  if (!!tag.uid) identifierType = 'NFC_TAG'
  if (!!tag.barcode) identifierType = 'SIMPLE_ITEM_IDENTIFIER'

  return identifierType
}

export const hasAsyncConfirm = (operation) => {
  return operation.executionModeForSyncConfirmation === 'async'
}

export const getEncodingAssociationStatus = (encodingCreateResponse, encodingValidationResponse) => {
  let associationStatus: 'TO_BE_READ' | 'CONFIRMED' | 'ERROR' | 'PROCESSING' | 'IGNORED' = 'TO_BE_READ'
  if (!encodingCreateResponse) associationStatus = 'TO_BE_READ'
  else {
    if (encodingCreateResponse?.success === true) associationStatus = 'CONFIRMED'
    if (
      (encodingCreateResponse?.errors ?? encodingValidationResponse?.errors ?? []).filter(
        (e) => e.errorCode !== 'Missing Mandatory Identifier'
      ).length > 0
    )
      associationStatus = 'ERROR'
  }
  return associationStatus
}

export const getSseEndpoint = (config) => {
  let endpoint = config.endpoint
  if (endpoint[endpoint.length - 1] === '/') {
    endpoint = endpoint.substring(0, endpoint.length - 1)
  }
  return (
    endpoint +
    '/api/v1/sse/getData/asyncExecutionOutcome/' +
    AppStore.loggedUser?.id +
    '?access_token=' +
    AppStore.authToken
  )
}

export const checkItemsCounterError = (items) => {
  const errorItems = items.filter((itm) => itm.item?.status === 'error')
  const warningItems = items.filter((itm) => itm.item?.status === 'warning')
  const ignoredItems = items.filter((itm) => itm.item?.status === 'ignored')
  const ignoredWithReasonItems = items.filter((itm) => itm.item?.status === 'ignoredWithReason')
  return errorItems.length + warningItems.length + ignoredItems.length + ignoredWithReasonItems.length > 0
}

export const getAutomaticRestartAntenna = (operation) => {
  return (
    operation.options &&
    operation.options.automaticRestartAntenna &&
    operation.options.automaticRestartAntenna === 'true'
  )
}

export const isModalError = (operation) => {
  return operation.errorMessageView && operation.errorMessageView === 'modal'
}

export function arraysContainSameElements(arr1: string[], arr2: string[]): boolean {
  return arr1.length === arr2.length && arr1.sort().join(',') === arr2.sort().join(',')
}

export const decToHex = (dec: number): string => {
  return dec.toString(16)
}

export const hexToString = (hex: string): string => {
  let str = ''
  for (let i = 0; i < hex.length; i += 2) {
    str += String.fromCharCode(parseInt(hex.substr(i, 2), 16))
  }
  return str
}

export const bytesToHex = (bytes: any) => {
  const hex: string[] = []
  for (let i = 0; i < bytes.length; i++) {
    hex.push((bytes[i] >>> 4).toString(16))
    hex.push((bytes[i] & 0xf).toString(16))
  }
  return hex.join('')
}

export const hexToBytes = (hex: string) => {
  const bytes: number[] = []
  for (let c = 0; c < hex.length; c += 2) {
    bytes.push(parseInt(hex.substr(c, 2), 16))
  }
  return bytes
}

export const reverseString = (str: string) => {
  let result = ''
  for (let i = 0; i < str.length; i += 2) {
    result = str.substr(i, 2) + result
  }
  return result
}

export const putSpacesEveryByte = (str: string) => {
  return str.match(/.{1,2}/g)?.join(' ') ?? ''
}

export const splitAndConvertASCII = (input) => {
  let result = ''
  for (let i = 0; i < input.length; i += 2) {
    result += String.fromCharCode(parseInt(input.slice(i, i + 2), 16))
  }
  return result
}

export const handleDownload = (fileName: string, content: string, mimeType = 'text/plain') => {
  const text = content
  const element = document.createElement('a')
  const file = new Blob([text], { type: mimeType })
  element.href = URL.createObjectURL(file)
  element.download = fileName
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

export const getSortingLabel = (currentSortingGroupRead?: SortingGroupRead) => {
  if (currentSortingGroupRead?.assignedEntry?.sortingGroup) {
    const { code, description } = currentSortingGroupRead.assignedEntry.sortingGroup

    if (description && description !== '' && description !== null) {
      return `${code} - ${description}`
    }

    return code
  }

  return '---'
}

export const hasChecklist = (operation, data?: Asn | ShippingParcel) => {
  let checklist = operation && 'hasChecklist' in operation && operation.hasChecklist
  if (checklist && data && data.checklistId && data.checklistId !== null) checklist = false
  return checklist
}

const getWithUserConfirmationMessageBase = (errors, response?): [string, any] => {
  let errorCheck = errors.find(
    (e) => e.errorCode === 'TAG_ALREADY_ASSOCIATED' || e.errorCode === 'ALREADY_ASSOCIATED_TAG'
  )
  if (errorCheck) {
    return [__(T.error.force_tag_associated), errorCheck]
  }
  errorCheck = errors.find((e) => e.errorCode === 'MISSING_MANDATORY_QC')
  if (errorCheck) {
    return [__(T.error.missing_mandatory_qc), errorCheck]
  }
  errorCheck = errors.find((e) => e.errorCode === 'WRONG_QC_STATUS')
  if (errorCheck) {
    return [__(T.error.wrong_qc_status), errorCheck]
  }
  errorCheck = errors.find((e) => e.errorCode === 'WRONG_QC_SIZE')
  if (errorCheck) {
    if (response.item && response.item.product && errorCheck.payload && errorCheck.payload.manualSize) {
      return [
        __(T.error.wrong_qc_size_payload, {
          productSize: response.item.product.size?.value || 'n/a',
          manualSize: errorCheck.payload.manualSize || 'n/a',
        }),
        errorCheck,
      ]
    }
    return [__(T.error.wrong_qc_size), errorCheck]
  }
  errorCheck = errors.find((e) => e.errorCode === 'WRONG_QC_MODEL')
  if (errorCheck) {
    return [__(T.error.wrong_qc_model), errorCheck]
  }
  return [__(T.error.tags_errors), {}]
}

export const getWithUserConfirmationMessage = (errors, response?) => {
  const [baseMessage, foundError] = getWithUserConfirmationMessageBase(errors, response)

  const errorMessageBE = foundError && foundError.payload?.errorMessage ? foundError.payload.errorMessage : ''

  if (errorMessageBE) return `${baseMessage} \n ${errorMessageBE}`

  return baseMessage
}

export const getAsnParcelStatusChip = (status): 'warning' | 'error' | 'default' | 'success' => {
  switch (status) {
    case 'INBOUND_ERROR':
    case 'OUTBOUND_ERROR':
      return 'error'

    case 'RECEIVE_AWAIT':
    case 'SEND_AWAIT':
    case 'DRAFT':
      return 'warning'

    case 'READY_TO_RECEIVE':
    case 'RECEIVED':
      return 'success'

    case 'IN_INBOUND':
    case 'READY_TO_SEND':
    case 'IN_TRANSIT':
    default:
      return 'default'
  }
}

export const getTimeoutValidateTags = (operation, removeIgnoredTags = false) => {
  const { options } = operation
  let timeoutValidateTags = options.timeoutValidateTags ?? 700
  //if enabled ignoreUnknownTags and time is 0 then set time to 700 as default
  if (removeIgnoredTags && timeoutValidateTags !== undefined && parseInt(timeoutValidateTags + '', 10) === 0)
    timeoutValidateTags = 700
  return timeoutValidateTags
}

export const getEncodingTagPassword = (operation) => {
  if (operation.options && operation.options.encodingTagPassword && operation.options.encodingTagPassword !== '') {
    return operation.options.encodingTagPassword
  }
  return undefined
}

export const getEncodingKillTagPassword = (operation) => {
  if (
    operation.options &&
    operation.options.encodingKillTagPassword &&
    operation.options.encodingKillTagPassword !== ''
  ) {
    return operation.options.encodingKillTagPassword
  }
  return undefined
}

export const getAutocompletePostData = (endpoint, params) => {
  if (endpoint.indexOf('?') !== -1) {
    const arr = endpoint.split('?')
    if (arr.length > 0) {
      const additionalParam = JSON.parse(
        '{"' + decodeURI(arr[1]).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}'
      )
      params = {
        ...params,
        ...additionalParam,
      }
    }
  }
  return params
}

export const formatTestId = (value: string | number | undefined): string => {
  if (!value) return ''
  return String(value)
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-') // sostituisce uno o più spazi con un singolo trattino
    .replace(/[^a-z0-9-]/g, '') // rimuove tutti i caratteri non alfanumerici e non trattini
}

export function getCustomErrorCodes(status?: number): { [key: string]: string } {
  switch (status) {
    default:
      return {}
  }
}
