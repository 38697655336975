import styled from '@emotion/styled'
import { Box, Icons, RowLine } from 'stylewhere/components'

export function InfoCard({
  title,
  subtitle,
  item,
  onClose,
}: {
  title?: string
  subtitle?: string
  item: any
  onClose?: (e: any) => void
}) {
  return (
    <CardContainer data-testid={`info-card`} data-cy={title}>
      <Title>{title}</Title>
      <SubTitle>{subtitle}</SubTitle>
      <Box>
        {item &&
          Object.keys(item).map((key) => (
            <KeyValue key={`info-${key}`}>
              <Box
                row
                style={{
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                  marginBottom: 2,
                }}
              >
                <KeyText data-testid="key-text">{key}</KeyText>
                <ValueText data-testid="value-text">{item[key] && item[key] !== '' ? item[key] : '---'}</ValueText>
              </Box>
              <RowLine />
            </KeyValue>
          ))}
      </Box>
      {onClose && <Icons.Close style={{ position: 'absolute', right: 15, cursor: 'pointer' }} onClick={onClose} />}
    </CardContainer>
  )
}

const CardContainer = styled.div`
  background: #ffffff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display: flex;
  padding: 15px;
  flex-direction: column;
  position: relative;
`
const KeyValue = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
  margin-top: 10px;
`
const KeyText = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  padding-left: 5px;
`
const ValueText = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-align: right;
  padding: 0 5px;
`

const Title = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  display: flex;
  align-items: center;
  opacity: 0.4;
`

const SubTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  display: flex;
  align-items: center;
`
